import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Flex } from 'rebass';

const Icon = styled.svg`
  fill: #555;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 0.5rem;
`;

const Subchapters = styled(Box)`
  padding-left: 2rem;
  padding-top: 0.5rem;
`;

const BlockContainer = styled.div`
  position: relative;
`;

function useScrollPercentage(containerId) {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const getScrollPercentage = () => {
    const h = document.documentElement,
      b = document.body,
      st = 'scrollTop',
      sh = 'scrollHeight';

    const percentage =
      ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;

    setScrollPercentage(percentage);
  };

  useEffect(() => {
    window.addEventListener('scroll', getScrollPercentage);
    return () => {
      window.removeEventListener('scroll', getScrollPercentage);
    };
  }, []);

  return scrollPercentage;
}

const Toc = React.memo(({ toc, description, showMeter = false }) => {
  const h1 = toc.find((h) => h.depth === 1);
  const text = encodeURIComponent(h1.value);
  const [url, setUrl] = React.useState(undefined);
  const [source, setSource] = React.useState(undefined);
  React.useEffect(() => {
    setUrl(encodeURIComponent(window.location.href));
    setSource(encodeURIComponent(window.location.origin));
  }, []);
  const desc = encodeURIComponent(description);
  const scrollPercentage = useScrollPercentage();

  return (
    <div className="relative">
      {!!showMeter && (
        <div
          className="absolute bottom-0 w-1 h-full max-h-screen transition-all ease-out bg-blue-600 -top-6 -right-12"
          style={{
            height: `${scrollPercentage * 1.5}%`,
          }}
        />
      )}
      <Flex>
        <Box mt={3}>
          <a
            className="resp-sharing-button__link"
            href={`https://twitter.com/intent/tweet/?text=${text}&amp;url=${url}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Partager sur Twitter"
          >
            <div className="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--large">
              <div
                aria-hidden="true"
                className="resp-sharing-button__icon resp-sharing-button__icon--solid"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z" />
                </svg>
              </div>
            </div>
          </a>
          <a
            className="resp-sharing-button__link"
            href={` http://www.linkedin.com/shareArticle?mini=true&url=${url}&summary=${desc}&source=${source}&title=${text}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Partager sur LinkedIn"
            style={{ marginLeft: '0.5rem' }}
          >
            <div className="resp-sharing-button resp-sharing-button--linkedin resp-sharing-button--large">
              <div
                aria-hidden="true"
                className="resp-sharing-button__icon resp-sharing-button__icon--solid"
              >
                <svg
                  width="1792"
                  height="1792"
                  viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z" />
                </svg>
              </div>
            </div>
          </a>
        </Box>
      </Flex>
      <div className="mt-4 text-lg text-gray-500">Sommaire</div>
      <BlockContainer>
        <div className="grid grid-flow-row gap-4 divide-y divide-gray-200">
          {toc
            .filter((h) => h.depth !== 1)
            .map((h) => (
              <div className="pt-3" key={h.value}>
                <a
                  className="text-lg font-semibold text-gray-600 hover:underline"
                  href={`#${h.link}`}
                >
                  {h.value}
                </a>
                {!!h.children && (
                  <Subchapters>
                    {h.children.map((child) => (
                      <a
                        className="block py-1 text-base text-gray-700 hover:underline"
                        key={child.value}
                        href={`#${child.link}`}
                      >
                        <Icon
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
                        </Icon>
                        {child.value}
                      </a>
                    ))}
                  </Subchapters>
                )}
              </div>
            ))}
        </div>
      </BlockContainer>
    </div>
  );
});

export default Toc;
