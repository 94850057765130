export function extractFirstCtaLinkAndTextFromRawHtml(content: string) {
  const linkRx = /<a\s+class="leadadvisor-button-link"\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/;
  const buttonRx = /<button\s+class="leadadvisor-button"(.*)>\s*(.*)\s*<\/button>/;
  return {
    link: content.match(linkRx)?.[2],
    text: content.match(buttonRx)?.[2]?.trim(),
  };
}

function hex_to_ascii(str1) {
  var hex = str1.toString();
  var str = '';
  for (var n = 0; n < hex.length; n += 2) {
    str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
  }
  return str;
}

export function extractFirstCtaLinkAndTextFromParsedMarkdownString(
  content: string
) {
  const linkRx = /className:\s*"leadadvisor-button-link",(\r\n|\r|\n)+\s*href:\s+"(.*?)"/;
  const buttonRx = /className:\s*"leadadvisor-button",(\r\n|\r|\n)+\s*type:\s*"button"(\r\n|\r|\n)+\s*(.*?)"(.*?)"/;

  return {
    link: content.match(linkRx)?.[2],
    // Convert hexadecimal \x**
    text: content
      .match(buttonRx)?.[4]
      ?.trim()
      .replace(/\\x([a-zA-Z0-9]{2})/g, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      }),
  };
}

export function addCampaignToAllContentLinks({
  content,
  campaignValue,
}: {
  content: string;
  campaignValue: string;
}) {
  return content.replace(
    /href[=|:]\s*"((https:\/\/www.leadadvisor.fr|\/.)(((?!(utm_campaign|articles)).)*))"/g,
    (hrefAttributeWithLink) => {
      return `${hrefAttributeWithLink.slice(
        0,
        hrefAttributeWithLink.length - 1
      )}?utm_campaign=${campaignValue}&utm_source=articles"`;
    }
  );
}
