import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import { kebabCase, deburr } from 'lodash';
import { formatDistance } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import GithubSlugger from 'github-slugger';
import { ArticleContainer } from '../components/ui/ArticleContainer';

import Toc from '../components/Toc';
import { Tag, TagIcon } from '../components/ui';

const slugger = new GithubSlugger();
const slugify = (value) => {
  slugger.reset();
  return deburr(slugger.slug(value));
};

const headingsToToc = (headings) =>
  headings
    .map((h) => ({ ...h, link: slugify(h.value) }))
    .reduce(
      (acc, curr) => {
        const depth = curr.depth;

        return {
          h2s:
            depth === 2
              ? [
                  ...acc.h2s,
                  {
                    ...curr,
                    children: [],
                  },
                ]
              : [
                  ...acc.h2s.slice(0, -1),
                  {
                    ...acc.h2s[acc.h2s.length - 1],
                    children: [
                      ...(acc.h2s[acc.h2s.length - 1]
                        ? acc.h2s[acc.h2s.length - 1].children
                        : []),
                      curr,
                    ],
                  },
                ],
          currentDepth: depth,
        };
      },
      {
        h2s: [],
        currentDepth: 2,
      }
    );

export const BlogPostTemplate = ({
  content,
  description,
  tags,
  title,
  helmet,
  date,
  headings,
  showToc,
}) => {
  return (
    <ArticleContainer>
      <div>
        {helmet || ''}
        <h1 id={slugify(title)}>
          <span className="block mb-4 text-4xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl">
            {title}
          </span>
        </h1>
        <div className="mt-2 mb-8 text-xs text-gray-500">
          {formatDistance(new Date(date), new Date(), {
            locale: frLocale,
            addSuffix: true,
          })}
        </div>
        <div className="my-8 xl:hidden">
          <Toc
            toc={[
              { value: title, depth: 1, link: slugify(title) },
              ...headingsToToc(headings).h2s,
            ]}
            description={description}
          />
        </div>
        <article className="mx-auto prose prose-lg text-gray-600 prose-indigo">
          {content}
        </article>
        {!!tags && !!tags.length && (
          <Box my={3}>
            <Flex alignItems="center">
              <TagIcon
                css={`
                  flex-basis: 30px;
                `}
              />
              <Flex
                alignItems="center"
                css={`
                  display: inline-flex;
                  flex-wrap: wrap;
                `}
              >
                {tags.map((tag) => (
                  <Box ml={2} key={tag}>
                    <Tag to={`/tags/${kebabCase(tag)}`}>{tag}</Tag>
                  </Box>
                ))}
              </Flex>
            </Flex>
          </Box>
        )}
      </div>
    </ArticleContainer>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};
