import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import GithubSlugger from 'github-slugger';
import { deburr } from 'lodash';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Layout from '../components/Layout';
import Toc from '../components/Toc';
import { HTMLContent } from '../components/Content';
import Button from '../components/ui/Button';
import { BlogPostTemplate } from './BlogPostTemplate';
import {
  extractFirstCtaLinkAndTextFromRawHtml,
  extractFirstCtaLinkAndTextFromParsedMarkdownString,
  addCampaignToAllContentLinks,
} from '../utils/extract';
const slugger = new GithubSlugger();
const slugify = (value) => {
  slugger.reset();
  return deburr(slugger.slug(value));
};

const getSchemaOrgJSONLD = ({
  url,
  title,
  image,
  description,
  datePublished,
}) => {
  const schemaOrgJSONLD = [
    {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url,
      name: title,
      alternateName: 'Lead Advisor',
    },
  ];

  return [
    ...schemaOrgJSONLD,
    {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': url,
            name: title,
            image,
          },
        },
      ],
    },
    {
      '@context': 'https://schema.org',
      '@type': 'BlogPosting',
      url,
      name: title,
      alternateName: 'Lead Advisor',
      headline: title,
      image: {
        '@type': 'ImageObject',
        url: image,
      },
      description,
      author: {
        '@type': 'Person',
        name: 'David Khoy',
      },
      publisher: {
        '@type': 'Organization',
        url: 'https://www.leadadvisor.fr',
        logo: {
          '@type': 'ImageObject',
          name: 'Lead Advisor logo',
          width: '218',
          height: '45',
          url:
            'https://s3.eu-west-3.amazonaws.com/www.leadadvisor.fr/assets/logo.png',
        },
        name: 'Lead Advisor',
      },
      mainEntityOfPage: {
        '@type': 'WebSite',
        '@id': 'https://www.leadadvisor.fr',
      },
      datePublished,
      dateModified: datePublished,
    },
  ];
};

const headingsToToc = (headings) =>
  headings
    .map((h) => ({ ...h, link: slugify(h.value) }))
    .reduce(
      (acc, curr) => {
        const depth = curr.depth;

        return {
          h2s:
            depth === 2
              ? [
                  ...acc.h2s,
                  {
                    ...curr,
                    children: [],
                  },
                ]
              : [
                  ...acc.h2s.slice(0, -1),
                  {
                    ...acc.h2s[acc.h2s.length - 1],
                    children: [
                      ...(acc.h2s[acc.h2s.length - 1]
                        ? acc.h2s[acc.h2s.length - 1].children
                        : []),
                      curr,
                    ],
                  },
                ],
          currentDepth: depth,
        };
      },
      {
        h2s: [],
        currentDepth: 2,
      }
    );

const BlogPost = ({ data }) => {
  const { markdownRemark, mdx, site } = data;
  const post = markdownRemark || mdx;
  const isMdx = !!mdx;

  const rawContent = isMdx ? post.body : post.html;
  const postUrl = post.frontmatter.customUrl
    ? `${site.siteMetadata.siteUrl}/articles${post.frontmatter.customUrl}`
    : `${site.siteMetadata.siteUrl}/articles/${post.fields.slug}`;
  const contentWithCampaign = useMemo(
    () =>
      rawContent
        ? addCampaignToAllContentLinks({
            content: rawContent,
            campaignValue: post.frontmatter.customUrl
              ? post.frontmatter.customUrl.replace(/\//, '')
              : post.fields.slug,
          })
        : null,
    [rawContent, post.frontmatter.customUrl, post.fields.slug]
  );

  const ctaLinkAndText = useMemo(
    () =>
      isMdx
        ? extractFirstCtaLinkAndTextFromParsedMarkdownString(
            contentWithCampaign
          )
        : extractFirstCtaLinkAndTextFromRawHtml(contentWithCampaign),
    [isMdx, contentWithCampaign]
  );

  return (
    <Layout
      sidebarContent={
        post && post.frontmatter && post.frontmatter.showToc ? (
          <Toc
            toc={[
              {
                value: post.frontmatter.title,
                depth: 1,
                link: slugify(post.frontmatter.title),
              },
              ...headingsToToc(post.headings).h2s,
            ]}
            description={post.frontmatter.description}
            showMeter
          />
        ) : null
      }
      rightsidebarContent={
        !!ctaLinkAndText && !!ctaLinkAndText.link && !!ctaLinkAndText.text ? (
          <div className="flex justify-center py-2 mt-12 shadow-md xl:shadow-none bg-indigo-50 xl:bg-transparent xl:mr-8 xl:p-0">
            <a href={ctaLinkAndText.link}>
              <Button variant="secondary" className="xl:animate-pump">
                {ctaLinkAndText.text}
              </Button>
            </a>
          </div>
        ) : null
      }
    >
      <BlogPostTemplate
        content={
          isMdx ? (
            <MDXRenderer>{contentWithCampaign}</MDXRenderer>
          ) : (
            <HTMLContent content={contentWithCampaign} />
          )
        }
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s">
            <title>{`${
              post.frontmatter.metaTitle || post.frontmatter.title
            }`}</title>
            <link rel="canonical" href={postUrl} />
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta
              property="og:image"
              content={`${site.siteMetadata.siteUrl}${post.frontmatter.image.childImageSharp.fluid.src}`}
            />
            <meta property="og:title" content={`${post.frontmatter.title}`} />
            <meta property="og:url" content={postUrl} />
            <meta name="author" content="David Khoy de Lead Advisor" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="@lead_advisor" />
            <meta name="twitter:title" content={post.frontmatter.title} />
            <meta name="twitter:url" content={postUrl} />
            <meta
              name="twitter:description"
              content={`${post.frontmatter.description}`}
            />
            <meta
              name="twitter:image"
              content={`${site.siteMetadata.siteUrl}${post.frontmatter.image.childImageSharp.fluid.src}`}
            />
            <script type="application/ld+json">
              {JSON.stringify(
                getSchemaOrgJSONLD({
                  url: postUrl,
                  title: post.frontmatter.title,
                  image: `${site.siteMetadata.siteUrl}${post.frontmatter.image.childImageSharp.fluid.src}`,
                  description: post.frontmatter.description,
                  datePublished: post.frontmatter.date,
                })
              )}
            </script>
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        headings={post.headings}
        tableOfContents={post.tableOfContents}
        showToc={post.frontmatter.showToc}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    site: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query ArticleByID($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      headings {
        value
        depth
      }
      fields {
        slug
      }
      frontmatter {
        date
        title
        metaTitle
        description
        tags
        showInArticles
        showToc
        customUrl
        image {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
    mdx(id: { eq: $id }) {
      id
      body
      rawBody
      headings {
        value
        depth
      }
      fields {
        slug
      }
      frontmatter {
        date
        title
        metaTitle
        description
        tags
        showInArticles
        showToc
        image {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`;
